<style lang="scss">
@import "~@/assets/css/var";

.dialog_orr.my-dialog {
    .el-dialog {
        margin-bottom: 0 !important;
        background: url('~@/assets/img/dialog-main.png') #fff no-repeat center / 110% 95%;
        background-position: center bottom 23px;

        &__header {
            background: unset !important;
        }

        &__title {
            color: #000 !important;
        }

        &__close {
            color: #000 !important;
        }

        &__body {
            padding: 10px 20px 10px 160px !important;
        }
    }
}

.table-detail {
    display: flex;
    height: 600px;
    overflow: auto;

    .link-item+.link-item {
        margin-left: 10px;
    }

    &__data {
        overflow: auto;
        flex: 1;
        padding-right: 20px;

        .el-descriptions-item__label {
            vertical-align: middle;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        overflow: auto;
        box-sizing: border-box;
        border-left: 1px solid #E6E6E6;
        width: 250px;
        padding: 0 20px 30px;

        .panel {
            padding: 15px 0;
            flex: 1;
            height: 0;
            overflow-y: auto;
        }
    }

    &__title {
        color: $main;
        font-size: 14px;
        line-height: 1;
    }

    &__desc {
        margin: 13px 0;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: #333333;
    }

    &__btn {
        margin-bottom: 10px;
    }

    .el-descriptions-item__cell {
        line-height: 21px !important;
        padding: 8px 10px !important;
        border-color: #dadfeb !important;
        white-space: pre-wrap;
    }

    .el-descriptions-item__label {
        background: #fff;
        width: 135px;
        font-size: 13px;
        font-weight: 400;
        color: #010101;
        line-height: 21px !important;
        padding: 8px 10px !important;
    }

    .el-descriptions-item__content {
        font-size: 13px;
        font-weight: 400;
        color: #515151;

        .el-badge {
            vertical-align: top;
        }

        .el-badge__content {
            font-size: 10px;
        }
    }


    .my-comment {
        display: flex;
        margin: 0;

        * {
            font-size: 12px;
        }

        .btn {
            flex: 1;
        }

        .comment {
            width: 130px;
        }
    }
}

.tetx_red {
    color: $red;
}

.tetx_yellow {
    color: $yellow;
}

.tetx_green {
    color: $green;
}

.tetx_bold {
    font-weight: bold;
}
</style>

<template>
    <div class="table-detail" :style="full ? fullStyle : {}" @mousedown="baseEvent">
        <div class="table-detail__data">
            <el-descriptions :column="1" border>
                <el-descriptions-item v-for="item in realCols" :key="item.id" :label="item.showtxt + '：'">
                    <template slot="label">
                        <p v-html="item.showtxt + '：'"></p>
                    </template>
                    <template v-if="data[item.fieldName] === '无权限'">
                        <el-tag type="danger" @click="fieldAuth = true">无权限</el-tag>
                    </template>
                    <template v-else-if="item.fieldName === 'comName'">
                        <div class="my-comment">
                            <div class="btn">
                                <el-button type="text" @click="goSearchCom(data.comName)" v-show="data.comName">{{
                                    data[item.fieldName] }}</el-button>
                            </div>
                            <div class="comment-star comment"
                                v-if="$help.token.get() && data.comCode && !item.isComTotal">
                                <div class="comment-star__item">
                                    <i class="el-icon-loading" v-if="comTotal.starLoading"></i>
                                    <template v-else>
                                        <i class="el-icon-star-on" v-if="comTotal.currentUserAttention"
                                            @click="removeStarCom"></i>
                                        <i class="el-icon-star-off" v-else @click="addStarCom"></i>
                                    </template>
                                    {{ comTotal.attentionNumber || '' }}
                                </div>
                                <div class="comment-star__item">
                                    <i class="el-icon-chat-line-round" @click="seeCommentCom"></i>
                                    {{ comTotal.appraiseNumber || '' }}
                                </div>
                                <div class="comment-star__item">
                                    <i class="el-icon-edit-outline" @click="seeNoteCom"></i>
                                    {{ comTotal.noteBookNumber || '' }}
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="item.fieldName === 'compName'">
                        <div class="my-comment">
                            <div class="btn">
                                <el-button type="text" @click="goSearchComp(data.compName)" v-show="data.compName">{{
                                    data[item.fieldName] }}</el-button>
                            </div>

                            <div class="comment-star comment"
                                v-if="$help.token.get() && data.compCode && !item.isComTotal">
                                <div class="comment-star__item">
                                    <i class="el-icon-loading" v-if="compTotal.starLoading"></i>
                                    <template v-else>
                                        <i class="el-icon-star-on" v-if="compTotal.currentUserAttention"
                                            @click="removeStarComp"></i>
                                        <i class="el-icon-star-off" v-else @click="addStarComp"></i>
                                    </template>
                                    {{ compTotal.attentionNumber || '' }}
                                </div>
                                <div class="comment-star__item">
                                    <i class="el-icon-chat-line-round" @click="seeCommentComp"></i>
                                    {{ compTotal.appraiseNumber || '' }}
                                </div>
                                <div class="comment-star__item">
                                    <i class="el-icon-edit-outline" @click="seeNoteComp"></i>
                                    {{ compTotal.noteBookNumber || '' }}
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="['referenceNumber','acceptedNumber'].includes(item.fieldName)">
                        <el-button type="text" @click="emitSeeApproval(data[item.fieldName])"
                            v-show="data[item.fieldName]">{{ data[item.fieldName] }}</el-button>
                    </template>

                    <template v-else-if="item.fieldName === 'component'">
                        <span v-for="(item, index) in data.component" :key="index">
                            <span v-if="typeof (item) === 'object'">
                                <multi-level-jump :obj="item" :btnList="componentList"
                                    :name="'component'"></multi-level-jump>
                            </span>
                            <template v-else>{{ item }}</template>
                        </span>
                    </template>

                    <template v-else-if="item.fieldName === 'indication'">
                        <span v-for="(item, index) in data.indication" :key="index">
                            <span v-if="typeof (item) === 'object'">
                                <multi-level-jump :obj="item" :btnList="indicationList"
                                    :name="'indication'"></multi-level-jump>
                            </span>
                            <template v-else>{{ item }}</template>
                        </span>
                    </template>

                    <template v-else-if="item.fieldName === 'approvalNumberStateName'">

                        <span class="tetx_red" v-if="data.approvalNumberStateName === '注销'">{{ data[item.fieldName]
                            }}</span>
                        <span v-else>{{ data[item.fieldName] }}</span>
                    </template>

                    <template v-else-if="item.link">
                        <el-button type="text" @click="item.link(data)">{{ data[item.fieldName] }}</el-button>
                    </template>
                    <template v-else-if="item.toFileType === '文件'">
                        <template v-if="data[item.fieldName]">
                            <template v-if="data[item.fieldName].indexOf($variable.uploadSplitStr) > -1">
                                <el-link class="link-item" v-for="file in getList(data[item.fieldName])" :key="file.url"
                                    size="mini" target="_blank" type="primary" :href="file.url">{{ file.name
                                    }}</el-link>
                            </template>
                            <template v-else>
                                <el-link size="mini" target="_blank" type="primary"
                                    :href="data[item.fieldName]">查看</el-link>
                            </template>
                        </template>
                    </template>
                    <template v-else-if="item.toFileType === '富文本'">
                        <el-button type="primary" plain round v-show="data[item.fieldName]"
                            @click="$store.commit('showHtml', data[item.fieldName])">查看</el-button>
                    </template>
                    <!-- 药品相互作用-药品名称 -->
                    <template v-else-if="item.fieldName === 'firstDataName'">
                        <el-badge :value="data.firstPurposeName" class="item"></el-badge>
                        {{ data.firstDataName }}
                    </template>
                    <!-- 药品相互作用-作用物名称 -->
                    <template v-else-if="item.fieldName === 'secondDataName'">
                        <el-badge :value="data.secondPurposeName" class="item"></el-badge>
                        {{ data.secondDataName }}
                    </template>
                    <!-- 药品相互作用-作用物名称 -->
                    <template v-else-if="item.fieldName === 'jointMechanism'">
                        <span class="tetx_yellow tetx_bold" v-if="data.jointMechanism === '谨慎合用（P）'">{{
                            data.jointMechanism }}</span>
                        <span class="tetx_green tetx_bold" v-else-if="data.jointMechanism === '可以合用（C）'">{{
                            data.jointMechanism }}</span>
                        <span class="tetx_red tetx_bold" v-else-if="data.jointMechanism === '避免合用（A）'">{{
                            data.jointMechanism }}</span>
                    </template>

                    <template
                        v-else-if="name === 'MyStarComment' && item.fieldName === 'context' && item.key === 'context'">
                        <el-button style="line-height: 10px;padding: 7px 10px;" type="primary" round
                            @click="clickContext(data[item.listName], item.value)" size="mini">查看</el-button>
                    </template>
                    <template v-else-if="name === 'MyNote' && item.fieldName === 'context' && item.key === 'context'">
                        <div v-if="data.sysUserNoteBookDTOList && data.sysUserNoteBookDTOList.length > 0">
                            <p v-for="(orr, i) in data.sysUserNoteBookDTOList" :key="i">{{ orr.context }}
                            </p>
                        </div>
                    </template>
                    <template v-else>{{ data[item.fieldName] }}</template>
                </el-descriptions-item>
            </el-descriptions>
        </div>
        <div class="table-detail__item" v-show="data.comName && mainKey">
            <p class="table-detail__title">按药品</p>
            <p class="table-detail__desc">{{ data.comName }}</p>
            <div class="panel">
                <p class="table-detail__btn" v-for="item in tags" :key="item.tableName">
                    <el-badge :value="item.total" :max="99">
                        <el-button type="primary" @click="goPage(item)">{{ item.name }}</el-button>
                    </el-badge>
                </p>
            </div>
        </div>
        <div class="table-detail__item" v-show="data.compName && mainKey">
            <p class="table-detail__title">按企业</p>
            <p class="table-detail__desc">{{ data.compName }}</p>
            <div class="panel">
                <p class="table-detail__btn" v-for="item in corpTags" :key="item.tableName">
                    <el-badge :value="item.total" :max="99">
                        <el-button @click="goCorpPage(item)">{{ item.name }}</el-button>
                    </el-badge>
                </p>
            </div>
        </div>

        <!--无权限弹窗-->
        <ts-dialog class="dialog_orr" :show.sync="fieldAuth" title="提示" hide-footer width="450px">
            <div class="field-auth">
                <p class="title">您还不是会员</p>
                <p class="assist">开通会员后即可查看全部数据</p>
                <div style="margin-top: 30px;">
                    <el-button plain type="primary" @click="$router.push('/members')" size="normal"
                        style="background: #fff;color: #5171DA;border-color: #5171DA;">去开通</el-button>
                    <el-button type="primary" @click="goCustom()" size="normal">联系客服</el-button>
                </div>
            </div>
        </ts-dialog>
        <customer-service ref="cs" :unSocket="false" />

        <drawer-content :drawerForm="drawerForm"></drawer-content>
    </div>
</template>

<script>
export default {
    props: ['data', 'cols', 'mainKey', 'full', 'indicationList', 'componentList', 'name'],
    data() {
        return {
            realCols: [],
            tags: [],
            corpTags: [],
            fullStyle: {
                height: (window.innerHeight - 121) + 'px'
            },
            fieldAuth: false,

            comTotal: {
                starLoading: false,
            },
            compTotal: {
                starLoading: false
            },

            drawerForm: {
                title: '',
                drawer: false,
                direction: 'rtl',
                list: []
            },
        }
    },
    created() {
        // 构造列
        let arr = []
        arr = this.cols.filter(item => item.detailView === '是');
        this.realCols = arr
            .sort((a, b) => {
                return a.detailSort - b.detailSort;
            })
            .map(item => {
                let txt = item.viewName || item.name
                txt = txt.replace(/\\n/g, '')
                this.$set(item, 'showtxt', txt)
                return item
            })

        // 加载统计数据
        if (this.mainKey && this.data.comName) {
            this.$api.get('setting/aimBaseDrugs/statisticDrug', {
                comName: this.data.comName,
                excludeTable: this.mainKey
            })
                .then(data => {
                    if (data.code === '00000' && data.data && data.data.length > 0) {
                        this.tags = data.data;
                    }
                })
        }

        if (this.mainKey && this.data.compName) {
            this.$api.get('setting/aimBaseComp/statisticComp', {
                compName: this.data.compName,
                excludeTable: this.mainKey
            })
                .then(data => {
                    if (data.code === '00000' && data.data && data.data.length > 0) {
                        this.corpTags = data.data;
                    }
                })
        }

        // 加载关注数量和评价数量
        if (this.$help.token.get()) {
            if (this.data.comCode) {
                this.initCom()
            }
            if (this.data.compCode) {
                this.initComp()
            }
        }
    },
    methods: {
        clickContext(row, title) {
            this.drawerForm.title = title
            this.drawerForm.list = row
            this.drawerForm.drawer = true
        },
        goCustom() {
            if (window.removeFinished) {
                this.$refs.cs.showDialog()
            }
        },
        baseEvent() {
            window.hideSearchTip && window.hideSearchTip()
        },
        goPage(item) {
            let url = ''
            let _query = {}
            if (item.tableName === 'sale_year') {
                url = `/new/aim_drugs_drug`
                _query.comCode = this.data.comCode
            } else {
                url = `/store/${item.tableName}`
                _query.comName = `%${this.data.comName}%`
            }
            const { href } = this.$router.resolve({
                path: url,
                query: _query
            });
            window.open(href, "_blank");
        },
        getList(href) {
            return href.split(this.$variable.uploadSplitStr).map(item => {
                return {
                    url: item,
                    name: item.substr(item.lastIndexOf('/') + 1),
                }
            })
        },
        goCorpPage(item) {
            let url = ''
            let _query = {}
            if (item.tableName === 'sale_year') {
                url = `/new/aim_drugs_corp`
                _query.compCode = this.data.compCode
            } else {
                url = `/store/${item.tableName}`
                _query.compName = this.data.compName
            }
            const { href } = this.$router.resolve({
                path: url,
                query: _query
            });
            window.open(href, "_blank");
        },
        emitSeeApproval(data) {
            this.$emit('emitSeeApproval', data)
        },

        showComponent(e) {
            this.goComponentOrIndication(e, 'through-component')
        },
        showIndication(e) {
            this.goComponentOrIndication(e, 'through-indication')
        },
        goComponentOrIndication(e, path) {
            this.$emit('showSearch', {
                e,
                path
            })
        },


        // 收藏评论笔记
        initCom() {
            this.$api.get('sys/sysUserAttention/queryComNumber', { comCode: this.data.comCode })
                .then(res => {
                    this.comTotal = res.data
                })
        },
        initComp() {
            this.$api.get('sys/sysUserAttention/queryCompNumber', { compCode: this.data.compCode })
                .then(res => {
                    this.compTotal = res.data
                })
        },
        removeStarCom() {
            this.$set(this.comTotal, 'starLoading', true)
            this.$api.post('sys/sysUserAttention/deleteByIdList', [this.comTotal.currentUserAttention.id])
                .then(res => {
                    if (res.success) {
                        this.comTotal.attentionNumber = this.data.attentionNumber - 1
                        this.comTotal.currentUserAttention = null
                    } else {

                    }
                })
                .finally(() => {
                    this.$set(this.comTotal, 'starLoading', false)
                })
        },
        addStarCom() {
            this.$set(this.comTotal, 'starLoading', true)
            this.$api.post('sys/sysUserAttention/insertSysUserAttention', {
                type: 0,
                comCode: this.data.comCode,
                comName: this.data.comName,
            })
                .then(res => {
                    if (res.success) {
                        this.initCom()
                    } else {
                        this.$set(this.comTotal, 'starLoading', false)
                    }
                })
                .catch(() => {
                    this.$set(this.comTotal, 'starLoading', false)
                })
        },
        seeCommentCom() {
            this.$store.commit('showComment', {
                type: '0',
                comCode: this.data.comCode,
                comName: this.data.comName,
                cb: this.initCom,
            })
        },
        seeNoteCom() {
            this.$store.commit('showNote', {
                type: '0',
                comCode: this.data.comCode,
                comName: this.data.comName,
                cb: this.initCom,
            })
        },


        removeStarComp() {
            this.$set(this.compTotal, 'starLoading', true)
            this.$api.post('sys/sysUserAttention/deleteByIdList', [this.compTotal.currentUserAttention.id])
                .then(res => {
                    if (res.success) {
                        this.compTotal.attentionNumber = this.data.attentionNumber - 1
                        this.compTotal.currentUserAttention = null
                    } else {

                    }
                })
                .finally(() => {
                    this.$set(this.compTotal, 'starLoading', false)
                })
        },
        addStarComp() {
            this.$set(this.compTotal, 'starLoading', true)
            this.$api.post('sys/sysUserAttention/insertSysUserAttention', {
                type: 1,
                compCode: this.data.compCode,
                compName: this.data.compName,
            })
                .then(res => {
                    if (res.success) {
                        this.initComp()
                    } else {
                        this.$set(this.compTotal, 'starLoading', false)
                    }
                })
                .catch(() => {
                    this.$set(this.compTotal, 'starLoading', false)
                })
        },
        seeCommentComp() {
            this.$store.commit('showComment', {
                type: '1',
                compCode: this.data.compCode,
                compName: this.data.compName,
                cb: this.initComp,
            })
        },
        seeNoteComp() {
            this.$store.commit('showNote', {
                type: '1',
                compCode: this.data.compCode,
                compName: this.data.compName,
                cb: this.initComp,
            })
        },
    }
}
</script>