<style lang="scss">
@import "~@/assets/css/var";
$lightDoubleMain: #6684EC;
$processBtnGreen: #4caf50;
$processFontColor: #b71c1c;

.el-popover {
    margin-bottom: 0 !important;
}

.my-table {
    .el-table__body-wrapper {
        z-index: 3;
    }

    .el-table__body tr.hover-row>td,
    .el-table__body tr.hover-row.current-row>td,
    .el-table__body tr.hover-row.el-table__row--striped>td,
    .el-table__body tr.hover-row.el-table__row--striped.current-row>td,
    .el-table__body tr.current-row>td {
        /*background-color: #c6d9f0 !important;*/
        background-color: #EDF1FF !important;
    }

    .el-table .caret-wrapper {
        width: 17px;
        height: 17px;
    }

    flex: 1;
    padding: 10px;
    background: #fff;
    box-sizing: border-box;

    .descending .sort-caret.descending {
        border-top-color: #fff;
    }

    .el-table .ascending .sort-caret.ascending {
        border-bottom-color: #fff;
    }

    &__title {
        font-weight: bold;
        display: inline-block;
        margin-right: 12px;
        line-height: 24px;
    }

    .el-dropdown {
        &.left {
            margin-right: 10px;
        }

        &.right {
            margin-left: 10px;
        }
    }

    &__operate {
        padding-bottom: 10px;

        &-right {
            float: right;
        }
    }

    .el-pagination {
        display: flex;
        justify-content: center;
        padding: 20px 0 0 0;
        font-size: 15px;

        .el-pagination__sizes {
            padding-left: 20px;
        }

        .number {
            border: 1px solid #DCDFE6;
            border-radius: 4px;
            margin: 0 4px;
            padding: 0 8px;
            min-width: unset;
            color: #606266;

            &.active {
                color: #fff;
                background: $main;
            }
        }
    }

    .el-table {
        &__header {
            th {
                line-height: 30px;
                font-size: 14px;
                background: #6684EC !important;
                color: #fff;
                text-align: center;
                font-weight: normal;

                .cell {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    line-height: 1.4;
                    padding: 5px;

                    .ascending {
                        top: -3px;
                    }

                    .descending {
                        top: 8px;
                    }

                    .sort-caret {
                        left: 3px;
                        //font-size: 8px;
                    }

                    //white-space: nowrap;
                    //text-overflow: ellipsis;
                    //overflow: hidden;
                    //word-break: break-all;
                }
            }
        }

        &__cell {
            padding: 5px 0;

            .cell {
                line-height: 16px;
                font-size: 12px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                word-break: break-all;
                /*text-align: center;*/
            }

            .el-button {
                word-break: break-all;
                line-height: 16px;
                font-weight: normal;
                white-space: normal;
                text-align: left;
                padding: 0;
            }

            .detail-button {
                padding: 0 12px;
                line-height: 23px;
            }

            .el-badge__content {
                font-size: 10px;
            }
        }
    }

    .table_popover {
        position: absolute;
        left: 5px;
        top: -4px;

        .el-button {
            padding: unset;
        }
    }

}

.detail-dialog {
    transition: all .3s;

    .el-dialog {
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
    }

    .el-dialog__header {
        position: relative;
        padding: 0 25px;
        box-sizing: border-box;
        line-height: 60px;
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        border-bottom: 1px solid #E6E6E6;

        span {
            position: absolute;
            right: 25px;
            top: 50%;
            transform: translateY(-50%);
        }

        i {
            padding-left: 15px;
            cursor: pointer;
        }

        .el-icon-full-screen {
            &:hover {
                color: $main;
            }
        }

        .el-icon-close {
            padding-left: 15px;

            &:hover {
                color: $red;
            }
        }
    }
}

.contrast-dialog {
    transition: all .3s;

    .el-dialog {
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
    }

    .el-dialog__header {
        position: relative;
        padding: 0 25px;
        box-sizing: border-box;
        line-height: 60px;
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        border-bottom: 1px solid #E6E6E6;

        span {
            position: absolute;
            right: 25px;
            top: 50%;
            transform: translateY(-50%);
        }

        i {
            padding-left: 15px;
            cursor: pointer;
        }

        .el-icon-full-screen {
            &:hover {
                color: $main;
            }
        }

        .el-icon-close {
            padding-left: 15px;

            &:hover {
                color: $red;
            }
        }
    }
}


.el-tooltip__popper.is-light {
    box-shadow: -7px 7px 40px 0 rgba(100, 100, 100, 0.3);
    border: 0;

    &[x-placement^="top"] .popper__arrow {
        border-top-color: #fff;
    }

    &[x-placement^="bottom"] .popper__arrow {
        border-bottom-color: #fff;
    }
}

.approval-dialog {
    .el-dialog {
        margin: 0 auto;
    }

    .el-dialog__header {
        height: 35px;
        line-height: 38px;
        padding: 0 20px;
        background: url(../assets/img/dialog-head.png) no-repeat 50% / 110% 110%;

        .el-dialog__title,
        .el-dialog__close {
            color: #fff;
        }

        .el-dialog__headerbtn {
            top: 0;
        }
    }

    .el-dialog__body {
        padding: unset;
    }
}

.dialog_orr.my-dialog {
    .el-dialog {
        margin-bottom: 0 !important;
        background: url('~@/assets/img/dialog-main.png') #fff no-repeat center / 110% 95%;
        background-position: center bottom 23px;

        &__header {
            background: unset !important;
        }

        &__title {
            color: #000 !important;
        }

        &__close {
            color: #000 !important;
        }

        &__body {
            padding: 10px 20px 10px 160px !important;
        }
    }
}


.field-auth {
    text-align: center;
    padding: 30px 0 20px 0;

    .title {
        font-size: 20px;
        line-height: 1;
        font-weight: 500;
        color: #333333;
    }

    .assist {
        padding-top: 10px;
    }
}

.search-tip {
    position: fixed;
    z-index: 10000;
    cursor: pointer;
    padding: 5px 10px;
    background: #fff;
    background: $main;
    color: #fff;
    font-weight: bold;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .3), 0 0 6px rgba(0, 0, 0, .2);

    /*&:hover {*/
    /*color: #999;*/
    /*}*/

    i {
        margin-left: 10px;
        font-weight: bold;
    }
}

.floating_button {
    position: absolute;
    top: 0px;
    right: 1px;
    display: flex;
    justify-content: flex-end;
    color: #5171DA;
    flex-direction: row-reverse;

    .icon {
        padding: 2px 2px;
    }
}
</style>
<template>
    <div ref="ele" class="my-table">
        <!-- style="padding-bottom: 15px" -->
        <div ref="top" class="flex" v-if="!hideTableHead" style="padding-bottom: 8px;">
            <div class="flex-item flex">
                <slot name="left"></slot>
            </div>
            <div style="text-align: right">
                <slot name="right"></slot>
            </div>
        </div>
        <div style="display: flex;">
            <div :style="{ 'height': realHeight + 'px', }">
                <slot name="searchLeft"></slot>
            </div>
            <el-table ref="table" v-if="tableLoaded" border :data="data" :height="realHeight" v-loading="loading"
                :header-cell-style="handleTheadStyle" :sort-orders="['asc', 'desc', null]" :stripe="isStripe"
                tooltip-effect="light" :row-class-name="tableRowClassName" :row-style="tableRowStyle"
                :show-summary="summary || !!summaryMethod" :summary-method="summaryMethod"
                :highlight-current-row="isSingle" @current-change="handleSingleChange" @header-dragend="headerDragend"
                @sort-change="handleSort" @row-dblclick="handleRowDbClickChange" @row-click="handleRowClick"
                @cell-mouse-leave="showClickIcon = false" @cell-mouse-enter="ncrFormat"
                @selection-change="selectionChange">
                <template #empty>
                    <template v-if="errorCode === 'A0004'"><ts-error></ts-error></template>
                    <div v-else-if="error">{{ error }}</div>
                    <div v-else><ts-empty v-show="list.length > 0 && (!loading || stopEmptyHide)"
                            style="margin-top: 30px;"></ts-empty>
                    </div>
                </template>

                <el-table-column v-if="Checkbox" type="selection" width="40" align="center"></el-table-column>
                <!--序列-->
                <el-table-column v-if="!hideSerial" fixed type="index" width="50" label="#"
                    align="center"></el-table-column>

                <!--多选框-->
                <el-table-column v-if="multiple" fixed type="selection" width="40"></el-table-column>

                <el-table-column v-for="(item, i) in list" :key="i" :prop="item.fieldName" header-align="center"
                    :min-width="item.fieldName == 'registrationType' ? '95' : item.width" :width="item.maxWidth"
                    :align="getAlign(item.listClass)" :fixed="item.fixed" :sortable="getOrder(item.listOrderView)">

                    <template #header>
                        <template v-if="item.isIcon">
                            <el-popover class="table_popover " placement="top-start" width="100" trigger="hover">
                                <p style="font-size: 10px;margin: 0 10px;">{{ item.iconText }}</p>
                                <el-button slot="reference" class="txt-btn" type="text" icon="el-icon-info"
                                    style="color: #45d75f;"></el-button>
                            </el-popover>
                            <p v-html="item.showtxt"></p>
                        </template>
                        <template v-else-if="item.fieldName == 'priceState'">
                            <el-popover class="table_popover " placement="top-start" width="150" trigger="hover">
                                <p style="font-size: 10px;margin: 0 10px;">近期趋势：近2次该企业药品的全国价格加权平均值趋势</p>
                                <el-button slot="reference" class="txt-btn" type="text" icon="el-icon-info"
                                    style="color: #45d75f;"></el-button>
                            </el-popover>
                            <p v-html="item.showtxt"></p>
                        </template>
                        <template v-else-if="item.fieldName == 'fivePriceState'">
                            <el-popover class="table_popover " placement="top-start" width="150" trigger="hover">
                                <p style="font-size: 10px;margin: 0 10px;">近5年趋势：近5年该企业药品的全国价格加权平均值趋势</p>
                                <el-button slot="reference" class="txt-btn" type="text" icon="el-icon-info"
                                    style="color: #45d75f;"></el-button>
                            </el-popover>
                            <p v-html="item.showtxt"></p>
                        </template>
                        <template v-else-if="item.fieldName == 'registeredClassification'">
                            <el-popover class="table_popover " placement="top-start" width="150" trigger="hover">
                                <p style="font-size: 10px;margin: 0 10px;">自2020年7月1日后申请的药品分类为3类，4类以及5.2类，视同通过一致性评价</p>
                                <el-button slot="reference" class="txt-btn" type="text" icon="el-icon-info"
                                    style="color: #45d75f;"></el-button>
                            </el-popover>
                            <p v-html="item.showtxt"></p>
                        </template>
                        <template v-else-if="item.fieldName == 'certificateNumber'">
                            <el-popover class="table_popover " placement="top-start" width="120" trigger="hover">
                                <p style="font-size: 10px;margin: 0 10px;">国产批文与进口批文</p>
                                <el-button slot="reference" class="txt-btn" type="text" icon="el-icon-info"
                                    style="color: #45d75f;"></el-button>
                            </el-popover>
                            <p v-html="item.showtxt"></p>
                        </template>

                        <p v-else v-html="item.showtxt"></p>
                    </template>
                    <template slot-scope="scope">
                        <slot :name="'slot_' + item.fieldName" :props="scope">
                            <!-- <template v-if="item.fieldName == 'registration_type'">
                            <el-tag type="danger" @click="fieldAuth = true">无权限</el-tag>
                        </template> -->
                            <template v-if="scope.row[scope.column.property] === '无权限'">
                                <el-tag type="danger" @click="fieldAuth = true">会员查看</el-tag>
                            </template>
                            <template v-else-if="item.fieldName === 'comName'">
                                <div class="floating_button"
                                    v-if="showClickIcon && rowid === scope.row.id && columnName === item.fieldName && $help.token.get()">
                                    <!-- <i class="el-icon-plus icon" @click="clickContrast(scope.row)"></i> -->
                                    <el-tooltip v-if="isContrastIcon" effect="light" content="加入对比"
                                        placement="top-start">
                                        <i class="el-icon-plus icon" @click="clickContrast(scope.row)"></i>
                                    </el-tooltip>
                                </div>
                                <el-button type="text"
                                    @click="item.medicinal ? seePriceChart(scope.row) : goSearchCom(scope.row.comName)"
                                    :style="{ fontSize: item.fontSize }" v-show="scope.row.comName">{{
                                        scope.row[scope.column.property] }}</el-button>
                            </template>
                            <template v-else-if="item.fieldName === 'compName'">
                                <el-button type="text" @click="goSearchComp(scope.row.compName)"
                                    :style="{ fontSize: item.fontSize }" v-show="scope.row.compName">{{
                                        scope.row[scope.column.property] }}</el-button>
                            </template>

                            <template v-else-if="item.fieldName === 'component'">
                                <span v-for="(item, index) in scope.row.component" :key="index">
                                    <span v-if="typeof (item) === 'object'">
                                        <multi-level-jump :obj="item" :btnList="componentList"
                                            :name="'component'"></multi-level-jump>
                                    </span>
                                    <template v-else>{{ item }}</template>
                                </span>
                            </template>

                            <template v-else-if="item.fieldName === 'indication'">
                                <span v-for="(item, index) in scope.row.indication" :key="index">
                                    <span v-if="typeof (item) === 'object'">
                                        <multi-level-jump :obj="item" :btnList="indicationList"
                                            :name="'indication'"></multi-level-jump>
                                    </span>
                                    <template v-else>{{ item }}</template>
                                </span>
                            </template>


                            <!--受理号-->
                            <template v-else-if="item.fieldName === 'referenceNumber'">
                                <el-button type="text" @click="seeApproval(scope.row.referenceNumber)"
                                    :style="{ fontSize: item.fontSize }">{{
                                        scope.row[scope.column.property] }}</el-button>
                            </template>
                            <!--批准文号-->
                            <template v-else-if="item.fieldName === 'acceptedNumber'">
                                <el-button type="text" @click="seeApproval(scope.row.acceptedNumber)"
                                    :style="{ fontSize: item.fontSize }" v-show="scope.row.compName">{{
                                        scope.row[scope.column.property] }}</el-button>
                            </template>
                            <template v-else-if="item.link">
                                <div style="color: #5171DA;cursor: pointer;" :style="{ fontSize: item.fontSize }"
                                    :title="item.explain" @click="item.link(scope.row, scope)">
                                    {{ scope.row[scope.column.property] }}
                                </div>
                            </template>
                            <template v-else-if="item.toFileType === '文件'">
                                <el-link v-show="scope.row[scope.column.property]" size="mini" target="_blank"
                                    type="primary" style="font-size: 12px" :href="scope.row[scope.column.property]"
                                    :underline="false">查看</el-link>
                            </template>
                            <template v-else-if="item.toFileType === '富文本'">
                                <el-button type="primary" plain round v-show="scope.row[scope.column.property]"
                                    @click="$store.commit('showHtml', scope.row[scope.column.property])">查看</el-button>
                            </template>
                            <!-- 药品相互作用-药品名称 -->
                            <template v-else-if="item.fieldName === 'firstDataName' && name === 'dr_interact'">
                                <div :title="zeroToEmpty ? (scope.row[scope.column.property] == 0 ? '' : scope.row[scope.column.property]) : scope.row[scope.column.property]"
                                    :style="{ fontSize: item.fontSize }">
                                    <el-badge :value="scope.row.firstPurposeName" class="item"></el-badge>
                                    {{ scope.row.firstDataName }}
                                </div>
                            </template>
                            <!-- 药品相互作用-作用物名称 -->
                            <template v-else-if="item.fieldName === 'secondDataName' && name === 'dr_interact'">
                                <div :title="zeroToEmpty ? (scope.row[scope.column.property] == 0 ? '' : scope.row[scope.column.property]) : scope.row[scope.column.property]"
                                    :style="{ fontSize: item.fontSize }">
                                    <el-badge :value="scope.row.secondPurposeName" class="item"></el-badge>
                                    {{ scope.row.secondDataName }}
                                </div>
                            </template>
                            <template v-else>
                                <div :title="zeroToEmpty ? (scope.row[scope.column.property] == 0 ? '' : scope.row[scope.column.property]) : scope.row[scope.column.property]"
                                    :style="{ fontSize: item.fontSize }">
                                    {{ zeroToEmpty ? (scope.row[scope.column.property] == 0 ? '' :
                                        scope.row[scope.column.property]) : scope.row[scope.column.property] }}
                                </div>
                            </template>
                        </slot>
                    </template>


                </el-table-column>

                <el-table-column v-for="(item, i) in cmd" :key="`${i}cmd`" :label="item.name" :min-width="item.width"
                    fixed="right">
                    <template slot-scope="props" v-if="whetherByField(props.row, item)">
                        <el-button type="text" @click="$emit('custom', props.row, item)">{{ item.text }}</el-button>
                    </template>
                </el-table-column>

                <el-table-column fixed="right" width="80" label="详情" v-if="!hideDetail">
                    <template slot-scope="scope">
                        <el-button class="detail-button" type="primary" round
                            @click="detailShow(scope.row)">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-pagination ref="page" v-if="!hidePage" :current-page.sync="pageParam.page" :page-sizes="pageSizes"
            :page-size="pageParam.pageSize" :layout="pageLayout" :total="pageParam.total"
            @size-change="handleSizeChange" @current-change="handleCurrentChange">
            <!--<span class="el-pagination__total">共<i>{{pageParam.total | toMoney}}</i>条数据</span>-->
            <slot name="page"></slot>
        </el-pagination>
        <!--详情弹窗-->
        <el-dialog class="detail-dialog" v-dialogDrag :visible.sync="detail.show" :show-close="false"
            :width="detail.full ? '100%' : '1130px'" append-to-body top="">
            <template #title>
                详情展示
                <span>
                    <i class="el-icon-full-screen" @click="detail.full = !detail.full"></i>
                    <i class="el-icon-close" @click="detail.show = false"></i>
                </span>
            </template>
            <ts-table-detail v-if="detail.show" :data="detail.data" :cols="cols" main-key="name" :full="detail.full"
                @emitSeeApproval="emitSeeApproval" @showSearch="goComponentOrIndicationHandle"
                :componentList="componentList" :indicationList="indicationList"></ts-table-detail>
        </el-dialog>

        <!--无权限弹窗-->

        <ts-dialog class="dialog_orr" :show.sync="fieldAuth" title="提示" hide-footer width="450px">
            <div class="field-auth">
                <p class="title">您还不是会员</p>
                <p class="assist">开通会员后即可查看全部数据</p>
                <div style="margin-top: 30px;">
                    <el-button plain type="primary" @click="$router.push('/members')" size="normal"
                        style="background: #fff;color: #5171DA;border-color: #5171DA;">去开通</el-button>
                    <el-button type="primary" @click="goCustom()" size="normal">联系客服</el-button>
                </div>
            </div>
        </ts-dialog>

        <!--受理号弹窗-->
        <el-dialog class="approval-dialog" :visible.sync="approval.show" title="信息展示" :show-close="true"
            :close-on-press-escape="false" :append-to-body="true" width="900px" :top="'0vh'">
            <div class="approval-dialog__content" v-loading="approval.loading">
                <div class="empty" v-if="!$help.token.get()">
                    <ts-error></ts-error>
                </div>
                <div class="empty" v-else-if="JSON.stringify(approval.data) === '{}'">
                    <ts-empty></ts-empty>
                </div>
                <div class="data_content" v-else>
                    <app-flow :approval-data="approval.data"></app-flow>
                </div>
            </div>
        </el-dialog>

        <customer-service ref="cs" :unSocket="false" />


        <!--可以搜索的成分和适应-->
        <!--        <div v-show="searchTip.show" class="search-tip" @mousedown.stop="goSearchThrough"-->
        <!--             :style="{left: searchTip.x + 'px',top: searchTip.y + 'px'}">{{searchTip.txt}}<i class="el-icon-paperclip"></i></div>-->
    </div>
</template>

<script>
import variable from '@/util/variable';
import api from '@/util/api';

const sort = {
    ascending: 'asc',
    descending: 'desc'
}

import AppFlow from './AppFlow';
export default {

    components: { AppFlow },
    props: {
        // 是否展示选择框
        Checkbox: {
            type: Boolean,
            default: false
        },
        // 列表是否在加载中summary
        loading: {
            type: Boolean,
            default: false
        },

        //在loading的时候ts-empty不隐藏
        stopEmptyHide: {
            type: Boolean,
            default: false
        },

        //控制页面的药品是否出现加入对比的加号
        isContrastIcon: {
            type: Boolean,
            default: true
        },

        // 是否统计
        summary: {
            type: Boolean,
            default: false
        },
        summaryMethod: {
            type: Function,
        },

        // 列的表头
        cols: {
            type: Array,
            default: () => {
                return []
            }
        },
        // 列的表头
        cmd: {
            type: Array,
            default: () => {
                return []
            }
        },
        // 表数据
        data: {
            type: Array,
            default: () => {
                return []
            }
        },

        // 表名
        tableName: {},
        name: {},

        // 0转空
        zeroToEmpty: {
            type: Boolean,
            default: false
        },

        //详情接口 / id字段
        detailApiId: {},


        height: {
            default: 250
        },
        full: {
            type: Boolean,
            default: false
        },

        // 是否单选
        isSingle: {
            type: Boolean,
            default: false
        },

        //隔行高亮
        isStripe: {
            type: Boolean,
            default: true
        },

        hideDetail: {
            type: Boolean,
            default: false
        },

        // 是否隐藏序列
        hideSerial: {
            type: Boolean,
            default: false
        },

        // 是否显示多选列
        multiple: {
            type: Boolean,
            default: false
        },

        error: {},
        errorCode: {},

        // 是否隐藏分页
        hidePage: {
            type: Boolean,
            default: false
        },
        // 页码的显示样式
        pageLayout: {
            type: String,
            // default: 'total, sizes, prev, pager, next, jumper'
            default: 'total, prev, pager, next, jumper, sizes, slot'
        },
        pageSizes: {
            type: Array,
            default: () => {
                return [10, 20, 50, 100]
            }
        },
        // 页码的相关参数
        pageParam: {
            type: Object,
            default: () => {
                return {
                    page: 1,
                    rows: 20,
                    total: 0
                }
            }
        },
        hideTableHead: {
            type: Boolean,
            default: false
        },

        tableRowStyle: Function,
    },
    data() {
        return {
            canvasId: 'relation-ship',
            sortKey: 'view' + this.name,
            forceComputed: 1,
            variable,
            curThead: [],

            tableLoaded: false,
            realHeight: 0,

            detail: {
                show: false,
                data: {},
                full: false,
            },
            contrast: {
                show: false,
                data: {},
            },

            fieldAuth: false,

            approval: {
                show: false,
                loading: false,
                full: false,
                approval: '',
                data: {},
                statusList: [
                    {
                        filed: 'pharmaToxi',
                        name: '药理毒理',
                        value: ''
                    },
                    {
                        filed: 'clinic',
                        name: '临床',
                        value: ''
                    },
                    {
                        filed: 'pharmacy',
                        name: '药学',
                        value: ''
                    },
                    {
                        filed: 'stats',
                        name: '统计',
                        value: ''
                    },
                    {
                        filed: 'clinicalPharma',
                        name: '临床药理',
                        value: ''
                    },
                    {
                        filed: 'compliance',
                        name: '合规',
                        value: ''
                    }
                ],
                colsOne: [
                    { key: 'referenceNumber', value: '受理号' },
                    { key: 'comName', value: '药品名称' },
                    { key: 'applicationTypeName', value: '申请类型' },
                    { key: 'compNames', value: '企业' },
                    { key: 'approvalNumber', value: '批号' },
                ],
                colsTwo: [
                    { key: 'priorApprovalName', value: '优先审批' },
                    { key: 'specialApprovalName', value: '特殊审评' },
                    { key: 'breakApprovalName', value: '突破性治疗药物' },
                    // { key: 'trinityApprovalName', value: '三合一序' },
                ],

                trinityApproval: [
                    { key: 'informInspectDate', value: '通知现场检查时间' },
                    { key: 'compApplyDate', value: '企业申请现场检查时间' },
                    { key: 'receiptTimeDate', value: '检查/检验报告接收时间' },
                    { key: 'endTime', value: '结束时间' },
                ],
                expressage: [
                    { key: 'sendDate', value: '送达时间' },
                    { key: 'scourierNumber', value: '快递单号' },
                    { key: 'addressee', value: '收件人' },
                    { key: 'scourierMobile', value: '手机号' },
                ],
                colorObj: {
                    'BZYWQD': '#e0e0e0',
                    'BZYYWCSP': '#388e3c',
                    'BZYPDDSP': '#ff9800',
                    'BZYZZSP': '#ef3000'
                }
            },

            searchTip: {
                show: false,
                txt: '',
                x: 0,
                y: 0,
                path: '',
            },
            //成分多级跳转
            componentList: [
                {
                    url: '/through-component',     //跳转连接
                    text: '按成分查药品',           //按钮名称
                    icon: 'el-icon-set-up',
                    params: [                        //跳转传参参数
                        { txt: 'code' }
                    ]
                },
                {
                    url: '/ch-medicinal-price',     //跳转连接
                    text: '中药材详情',           //按钮名称
                    icon: 'el-icon-set-up',
                    params: [                        //跳转传参参数
                        { comCode: 'chineseMedicinalCode' },
                        { comName: 'chineseMedicinalName' },
                    ]
                },
                {
                    // url: '/ch-medicinal-price',     //跳转连接
                    title: '成分',
                    text: '加入成分对比',           //按钮名称
                    icon: 'el-icon-set-up',
                    componentContrast: true,      //是否加入对比
                },
            ],
            //适应症多级跳转
            indicationList: [
                {
                    url: '/through-indication',     //跳转连接
                    text: '按疾病查药品',           //按钮名称
                    icon: 'el-icon-office-building',
                    params: [                        //跳转传参参数
                        { code: 'code' },
                        { name: 'name' },
                    ]
                },
                {
                    title: '适应症',
                    text: '加入适应症对比',           //按钮名称
                    icon: 'el-icon-office-building',
                    indicationContrast: true,      //是否加入对比
                },
            ],
            showClickIcon: false,
            rowid: '',
            columnName: '',
        }
    },
    mounted() {
        window.hideSearchTip = () => {
            this.searchTip.show = false
        }
        this.reloadHeight()
    },
    updated() {
        this.$nextTick(() => {
            this.$refs['table'] && this.$refs['table'].doLayout()
        })
    },
    computed: {
        list() {
            const cols = this.$help.deepClone(this.cols)
            if (cols && cols.length > 0) {
                let list = cols.map(item => {
                    let txt = item.viewName || item.name
                    txt = txt.replace(/\\n/g, '<br/>')
                    this.$set(item, 'showtxt', txt)
                    this.$set(item, 'model', [variable.searchType.DATE, variable.searchType.NUM].includes(item.listQueryModel) ? ['', ''] : '')
                    this.$set(item, 'loading', false)
                    this.$set(item, 'random1111111', this.forceComputed)
                    return item;
                })
                list = list.filter(item => item.listView === '是')
                // 判断是否本地隐藏字段
                const setList = this.$help.store.get(this.sortKey)
                list = list
                    .filter(item => {
                        const set = setList[item.fieldName]
                        if (typeof set === 'object') {
                            item.listStipulate = set.listStipulate || 0
                            this.$set(item, 'fontSize', set.fontSize ? `${set.fontSize}px` : undefined)
                            item.width = set.width
                            item.listViewSelf = set.listViewSelf

                            return item.listViewSelf === '是'
                        } else {
                            return true
                        }
                    })
                    .map(item => {
                        if (item.listStipulate === 1) {
                            item.fixed = 'left'
                        } else if (item.listStipulate === 2) {
                            item.fixed = 'right'
                        } else {
                            item.fixed = false
                        }
                        return item
                    })
                return list.sort((a, b) => {
                    return a.listSort - b.listSort;
                })
            }
            return [];
        },
    },
    methods: {
        goCustom() {
            if (window.removeFinished) {
                this.$refs.cs.showDialog()
            }
        },
        //药品加到药品对比里
        clickContrast(row) {
            this.$store.commit('setContrastShow', true)
            this.$store.commit('clickContrast', row)
        },
        ncrFormat(row, column) {
            this.showClickIcon = true
            this.rowid = row.id
            this.columnName = column.property
        },
        selectionChange(val) {
            this.$emit('selectionChange', val);
        },
        //调用祖父组件
        seePriceChart(row) {
            this.$emit('seePriceChartOne', row);
        },
        headerDragend() {
            this.$nextTick(() => {
                this.$refs['table'] && this.$refs['table'].doLayout()
            })
        },

        reloadHeight() {
            this.tableLoaded = false
            this.$nextTick(() => {
                if (this.full) {
                    this.realHeight = this.$refs.ele.clientHeight - (this.$refs.top ? this.$refs.top.clientHeight : 0) - (this.$refs.page ? this.$refs.page.$el.clientHeight : 0) - 20
                } else {
                    this.realHeight = this.height
                }
                this.tableLoaded = true
            })
        },

        getOrder(val) {
            if (val === '是') {
                return this.hidePage ? true : 'custom'
            } else if (val === '否') {
                return false
            }
            return !!val
        },

        // region 查看详情弹窗
        detailShow(row) {
            this.detail.show = true
            this.detail.data = row

            // 拿详情
            const detailApi = this.$parent.currentParam ? this.$parent.currentParam.detail : ''
            if (detailApi) {
                this.$api.getSelf(detailApi, { id: row.id })
                    .then(res => {
                        if (res.code === this.$variable.successCode) {
                            this.detail.data = res.data
                            this.setBooks()
                        }
                    })
            } else if (this.detailApiId) {
                this.$api.getSelf(`${this.detailApiId.api}?${this.detailApiId.id}=${row.id}`)
                    .then(res => {
                        if (res.success) {
                            this.detail.data = res.data
                            this.setBooks()
                        }

                    })

            }
        },
        setBooks() {
            if (this.$parent.config.key === 'du_instruction_books') {
                if (this.detail.data.componentLink) {
                    const arr = JSON.parse(this.detail.data.componentLink)
                    arr.sort((orr1, orr2) => {
                        return orr2.name.length - orr1.name.length
                    })
                    arr.forEach((j, i) => {
                        this.detail.data.component = this.detail.data.component.replace(j.name, `##{${i}}##`)
                    })
                    this.detail.data.component = this.setObj(this.detail.data.component.split('##'), arr)
                }
                if (this.detail.data.indicationLink) {
                    const arr = JSON.parse(this.detail.data.indicationLink)
                    arr.sort((orr1, orr2) => {
                        return orr2.name.length - orr1.name.length
                    })
                    arr.forEach((j, i) => {
                        this.detail.data.indication = this.detail.data.indication.replace(j.commonName || j.name, `##{${i}}##`)
                    })
                    this.detail.data.indication = this.setObj(this.detail.data.indication.split('##'), arr)
                }
            }
        },
        setObj(obj, list) {
            return obj.map(orr => {
                if (orr.indexOf("{") != -1) {
                    const t = orr.replace("{", "").replace("}", "")
                    orr = list[t]
                }
                return orr
            })
        },
        computedSortable(item) {
            if (item.listOrderView === '是') {
                return 'custom'
            } else if (item.listOrderView === '否') {
                return false;
            }
            return item.listOrderView;
        },
        sortSystem(a, b, key) {
            return a[key] - b[key]
        },

        // 批准文号弹窗显示
        seeApproval(approval) {
            this.$set(this.approval, 'data', {})
            this.approval.loading = true
            this.approval.show = true
            const approvalApi = 'flow/flowDevelopmentReviewProgress/pharmaceuticalResearchAndDevelopment'
            this.$api.get(approvalApi, { referenceNumber: approval }).then(res => {
                if (res.success) {
                    this.$set(this.approval, 'data', res.data)
                }
            })
                .finally(() => {
                    this.approval.loading = false
                })
        },

        emitSeeApproval(data) {
            this.seeApproval(data)
        },

        tableRowClassName({ row }) {
            if (row.hasChecked) {
                return 'used-row'
            }
            return '';
        },

        selectRemote(item, args, type) {
            if (type === 'focus' && item.list && item.list.length > 0) {
                return
            }

            item.loading = true;

            let keywords = []
            if (args) {
                keywords.push(args)
            }
            if (item.baseKeyword) {
                keywords.push(item.baseKeyword)
            }
            api.post('field-distinct', {
                tableName: this.name,
                fieldName: item.fieldName,
                pageSize: 34,
                keywords: keywords,
            })
                .then(res => {
                    item.list = res.data;
                })
                .finally(() => {
                    item.loading = false;
                })
        },
        filterSearchCommon() {
            this.$refs.btn.click();
            this.$emit('handleFilter');
        },
        filterCancelCommon(item) {
            this.$refs.btn.click();
            item.model = [variable.searchType.DATE, variable.searchType.NUM].includes(item.listQueryModel) ? ['', ''] : '';
            this.$emit('handleFilter');
        },
        getFilterClass(item) {
            if ([variable.searchType.DATE, variable.searchType.NUM].includes(item.listQueryModel)) {
                if (item.model[0] && item.model[1]) {
                    return 'used'
                }
            } else {
                if (item.model && item.model.length > 0) {
                    return 'used'
                }
            }
        },
        getAlign(cls) {
            switch (cls) {
                case 'tl':
                    return 'left'
                case 'tc':
                    return 'center'
                case 'tr':
                    return 'right'
            }
        },
        handleRowDbClickChange(row) {
            this.$emit('handleRowDbClickChange', row)
        },
        handleRowClick(row) {
            if (this.isSingle) {
                this.$refs.table.clearSelection()
            }
            this.$refs.table.toggleRowSelection(row)
            this.$emit('handleRowClickChange', row)
        },
        // 单选
        handleSingleChange(row) {
            this.$emit('handleSingleChange', row)
        },

        // 分页相关
        handleSizeChange(val) {
            this.$emit('handleSizeChange', val)
        },
        handleCurrentChange(val) {
            this.$emit('handleCurrentChange', val)
        },
        // 排序
        handleSort({ column, prop, order }) {
            if (column.sortable === true) {
                return
            }
            const param = {}

            param[prop] = sort[order]

            this.$emit('handleSort', param)
        },
        handleTheadStyle({ column }) {
            let index = this.curThead.findIndex(item => { return item.prop === column.property; })
            if (index > -1) {
                column.order = this.curThead[index].order;
            }
        },

        whetherByField(data, param) {
            if (param.fieldWhether && !data[param.fieldWhether]) {
                return false
            }
            return true
        },

        showComponent(e) {
            this.goComponentOrIndication(e, 'through-component')
        },
        showIndication(e) {
            this.goComponentOrIndication(e, 'through-indication')
        },
        goComponentOrIndication(e, path) {
            const select = window.getSelection()
            if (select.type === 'Range') {
                const val = select.toString()
                if (val) {
                    this.searchTip.x = e.pageX + 30
                    this.searchTip.y = e.pageY - 15
                    this.searchTip.show = true
                    this.searchTip.txt = val
                    this.searchTip.path = path
                }
            }
        },
        goComponentOrIndicationHandle(e) {
            this.goComponentOrIndication(e.e, e.path)
        },
        goSearchThrough() {
            const { href } = this.$router.resolve({
                path: `/${this.searchTip.path}?txt=${this.searchTip.txt}`,
            });
            window.open(href, "_blank");
        },
    }
}
</script>