import api from '@/util/api';
import help from '@/util/help';
import variable from '@/util/variable'

export default {
    data() {
        return {
            refer: {
                rd_registration_acceptances: {
                    page: 'rd/rdRegistrationAcceptances/queryRdRegistrationAcceptancesPage',
                    detail: 'rd/rdRegistrationAcceptances/findRdRegistrationAcceptancesById',
                    export: 'rd/rdRegistrationAcceptances/exportRdRegistrationAcceptances',
                },
                flow_development_review_progress: {
                    page: 'rd/flowDevelopmentReviewProgress/queryFlowDevelopmentReviewProgressPage',
                    detail: 'rd/flowDevelopmentReviewProgress/findFlowDevelopmentReviewProgressById',
                    export: 'rd/flowDevelopmentReviewProgress/exportFlowDevelopmentReviewProgress',
                },
                'flow_development_review_progress_patch-new-1': {
                    page: 'dce/flowDevelopmentReviewProgress/queryFlowDevelopmentReviewProgressPage',
                    detail: 'dce/flowDevelopmentReviewProgress/findFlowDevelopmentReviewProgressById',
                    export: 'dce/flowDevelopmentReviewProgress/exportFlowDevelopmentReviewProgress',
                },
                rd_new_drug_approval_infos: {
                    page: 'rd/rdNewDrugApprovalInfos/queryRdNewDrugApprovalInfosPage',
                    detail: 'rd/rdNewDrugApprovalInfos/findRdNewDrugApprovalInfosById',
                    export: 'rd/rdNewDrugApprovalInfos/exportRdNewDrugApprovalInfos',
                },
                rd_drug_clinical_trials: {
                    page: 'rd/rdDrugClinicalTrials/queryRdDrugClinicalTrialsPage',
                    detail: 'rd/rdDrugClinicalTrials/findRdDrugClinicalTrialsById',
                    export: 'rd/rdDrugClinicalTrials/exportRdDrugClinicalTrials',
                },
                dce_post_market_drugs: {
                    page: 'dce/dcePostMarketDrugs/queryDcePostMarketDrugsPage',
                    export: 'dce/dcePostMarketDrugs/exportDcePostMarketDrugs',
                    detail: 'dce/dcePostMarketDrugs/findDcePostMarketDrugsById'
                },
                dce_reference_products: {
                    page: 'dce/dceReferenceProducts/queryDceReferenceProductsPage',
                    export: 'dce/dceReferenceProducts/exportDceReferenceProducts',
                    detail: 'dce/dceReferenceProducts/findDceReferenceProductsById'
                },
                dce_reference_preparation_records: {
                    page: 'dce/dceReferencePreparationRecords/queryDceReferencePreparationRecordsPage',
                    export: 'dce/dceReferencePreparationRecords/exportDceReferencePreparationRecords',
                    detail: 'dce/dceReferencePreparationRecords/findDceReferencePreparationRecordsById'
                },
                // rd_registration_acceptances_patch: {
                //     page: 'dce/dceReferencePreparationRecords/queryDceReferencePreparationRecordsPage',
                //     export: 'dce/dceReferencePreparationRecords/exportDceReferencePreparationRecords',
                // },
                pi_national_drug_standards: {
                    page: 'pi/piNationalDrugStandards/queryPiNationalDrugStandardsPage',
                    export: 'pi/piNationalDrugStandards/exportPiNationalDrugStandards',
                    detail: 'pi/piNationalDrugStandards/findPiNationalDrugStandardsById'
                },
                pi_drug_disqualifications: {
                    page: 'pi/piDrugDisqualifications/queryPiDrugDisqualificationsPage',
                    export: 'pi/piDrugDisqualifications/exportPiDrugDisqualifications',
                    detail: 'pi/piDrugDisqualifications/findPiDrugDisqualificationsById'
                },
                mainf_policies: {
                    page: 'mainf/mainfPolicies/queryMainfPoliciesPage',
                    export: 'mainf/mainfPolicies/exportMainfPolicies',
                    detail: 'mainf/mainfPolicies/findMainfPoliciesById'
                },
                mainf_hospitals: {
                    page: 'mainf/mainfHospitals/queryMainfHospitalsPage',
                    export: 'mainf/mainfHospitals/exportMainfHospitals',
                    detail: 'mainf/mainfHospitals/findMainfHospitalsById'
                },
                mainf_drug_winning_informations: {
                    page: 'mainf/mainfDrugWinningInformations/queryMainfDrugWinningInformationsPage',
                    export: 'mainf/mainfDrugWinningInformations/exportMainfDrugWinningInformations',
                    detail: 'mainf/mainfDrugWinningInformations/findMainfDrugWinningInformationsById'
                },
                mainf_biological_product_sale_volumes: {
                    page: 'mainf/mainfBiologicalProductSaleVolumes/queryMainfBiologicalProductSaleVolumesPage',
                    export: 'mainf/mainfBiologicalProductSaleVolumes/exportMainfBiologicalProductSaleVolumes',
                    detail: 'mainf/mainfBiologicalProductSaleVolumes/findMainfBiologicalProductSaleVolumesById'
                },
                base_article_product: {
                    page: 'mainf/baseArticleProduct/queryBaseArticleProductPage',
                    export: 'mainf/baseArticleProduct/exportBaseArticleProduct',
                    detail: 'mainf/baseArticleProduct/findBaseArticleProductById'
                },
                base_article_competitor: {
                    page: 'mainf/baseArticleCompetitor/queryBaseArticleCompetitorPage',
                    export: 'mainf/baseArticleCompetitor/exportBaseArticleCompetitor',
                    detail: 'mainf/baseArticleCompetitor/findBaseArticleCompetitorById'
                },
                base_article_news: {
                    page: 'mainf/baseArticleNews/queryBaseArticleNewsPage',
                    export: 'mainf/baseArticleNews/exportBaseArticleNews',
                    detail: 'mainf/baseArticleNews/findBaseArticleNewsById'
                },
                base_comp_news: {
                    page: 'mainf/baseCompNews/queryBaseCompNewsPage',
                    export: 'mainf/baseCompNews/exportBaseCompNews',
                    detail: 'mainf/baseCompNews/findBaseCompNewsById'
                },
                du_instruction_books: {
                    page: 'du/duInstructionBooks/queryDuInstructionBooksPage',
                    export: 'du/duInstructionBooks/exportDuInstructionBooks',
                    detail: 'du/duInstructionBooks/findDuInstructionBooksById',
                    edit: {
                        add: true,
                        cols: [
                            {
                                key: 'comName',
                                value: '药品通用名',
                                type: variable.colType.fuzzy,
                                param: {},
                            },
                        ],
                    },
                },
                du_off_label_uses: {
                    page: 'du/duOffLabelUses/queryDuOffLabelUsesPage',
                    export: 'du/duOffLabelUses/exportDuOffLabelUses',
                    detail: 'du/duOffLabelUses/findDuOffLabelUsesById'
                },
                du_health_insurance_directories: {
                    page: 'du/duHealthInsuranceDirectories/queryDuHealthInsuranceDirectoriesPage',
                    export: 'du/duHealthInsuranceDirectories/exportDuHealthInsuranceDirectories',
                    detail: 'du/duHealthInsuranceDirectories/findDuHealthInsuranceDirectoriesById'
                },
                du_essential_drugs: {
                    page: 'du/duEssentialDrugs/queryDuEssentialDrugsPage',
                    export: 'du/duEssentialDrugs/exportDuEssentialDrugs',
                    detail: 'du/duEssentialDrugs/findDuEssentialDrugsById'
                },
                base_article_guide: {
                    page: 'base/baseArticleGuide/queryBaseArticleGuidePage',
                    export: 'base/baseArticleGuide/exportBaseArticleGuide',
                    detail: 'base/baseArticleGuide/findBaseArticleGuideById'
                },
                base_article_path: {
                    page: 'base/baseArticlePath/queryBaseArticlePathPage',
                    export: 'base/baseArticlePath/exportBaseArticlePath',
                    detail: 'base/baseArticlePath/findBaseArticlePathById'
                },
                base_article_literature: {
                    page: 'base/baseArticleLiterature/queryBaseArticleLiteraturePage',
                    export: 'base/baseArticleLiterature/exportBaseArticleLiterature',
                    detail: 'base/baseArticleLiterature/findBaseArticleLiteratureById'
                },
                trach_ancient_prescriptions: {
                    page: 'trach/trachAncientPrescriptions/queryTrachAncientPrescriptionsPage',
                    export: 'trach/trachAncientPrescriptions/exportTrachAncientPrescriptions',
                    detail: 'trach/trachAncientPrescriptions/findTrachAncientPrescriptionsById'
                },
                trach_medicine_prescriptions: {
                    page: 'trach/trachMedicinePrescriptions/queryTrachMedicinePrescriptionsPage',
                    export: 'trach/trachMedicinePrescriptions/exportTrachMedicinePrescriptions',
                    detail: 'trach/trachMedicinePrescriptions/findTrachMedicinePrescriptionsById'
                },
                trach_protected_varieties: {
                    page: 'trach/trachProtectedVarieties/queryTrachProtectedVarietiesPage',
                    export: 'trach/trachProtectedVarieties/exportTrachProtectedVarieties',
                    detail: 'trach/trachProtectedVarieties/findTrachProtectedVarietiesById'
                },
                co_drug_manufacturers: {
                    page: 'co/coDrugManufacturers/queryCoDrugManufacturersPage',
                    export: 'co/coDrugManufacturers/exportCoDrugManufacturers',
                    detail: 'co/coDrugManufacturers/findCoDrugManufacturersById'
                },
                co_gmp_authentications: {
                    page: 'co/coGmpAuthentications/queryCoGmpAuthenticationsPage',
                    export: 'co/coGmpAuthentications/exportCoGmpAuthentications',
                    detail: 'co/coGmpAuthentications/findCoGmpAuthenticationsById'
                },
                co_drug_handling_enterprises: {
                    page: 'co/coDrugHandlingEnterprises/queryCoDrugHandlingEnterprisesPage',
                    export: 'co/coDrugHandlingEnterprises/exportCoDrugHandlingEnterprises',
                    detail: 'co/coDrugHandlingEnterprises/findCoDrugHandlingEnterprisesById'
                },
                co_gsp_authentications: {
                    page: 'co/coGspAuthentications/queryCoGspAuthenticationsPage',
                    export: 'co/coGspAuthentications/exportCoGspAuthentications',
                    detail: 'co/coGspAuthentications/findCoGspAuthenticationsById'
                },
                dr_domestic_drugs: {
                    page: 'dr/drDomesticDrugs/queryDrDomesticDrugsPage',
                    export: 'dr/drDomesticDrugs/exportDrDomesticDrugs',
                    detail: 'dr/drDomesticDrugs/findDrDomesticDrugsById'
                },
                dr_imported_drugs: {
                    page: 'dr/drImportedDrugs/queryDrImportedDrugsPage',
                    export: 'dr/drImportedDrugs/exportDrImportedDrugs',
                    detail: 'dr/drImportedDrugs/findDrImportedDrugsById'
                },
                dr_material_drugs: {
                    page: 'dr/drMaterialDrugs/queryDrMaterialDrugsPage',
                    export: 'dr/drMaterialDrugs/exportDrMaterialDrugs',
                    detail: 'dr/drMaterialDrugs/findDrMaterialDrugsById'
                },
                dr_national_centralized_procurement: {
                    page: 'dr/drNationalCentralizedProcurement/queryDrNationalCentralizedProcurementPage',
                    export: 'dr/drNationalCentralizedProcurement/exportDrNationalCentralizedProcurement',
                    detail: 'dr/drNationalCentralizedProcurement/findDrNationalCentralizedProcurementById'
                },
                dr_province_centralized_procurement: {
                    page: 'dr/drProvinceCentralizedProcurement/queryDrProvinceCentralizedProcurementPage',
                    export: 'dr/drProvinceCentralizedProcurement/exportDrProvinceCentralizedProcurement',
                    detail: 'dr/drProvinceCentralizedProcurement/findDrProvinceCentralizedProcurementById'
                },
                //药物相互作用
                dr_interact: {
                    page: 'dr/drInteract/queryDrInteractPage',
                    export: 'dr/drInteract/exportDrInteract',
                    detail: 'dr/drInteract/findDrInteractById'
                },

            },
            currentParam: {
                page: '',
                export: '',
            },
            config: {
                key: '',
            },
            loaded: false,
            loading: true,
            list: [],
            cols: [],
            outSearchCols: [],
            outSearchForm: {}, // 暂存
            outSearchReal: [], // 实际给接口传的

            searchCols: [],
            pageParam: {
                page: 1,
                pageSize: variable.basePageSize,
                total: 0,
                error: '',
            },
            sortParam: {},

            sumData: {},

            noAuthField: [],

            // 详情弹窗
            drawer: {
                show: false,
                id: '',
            },
            error: '',

            specialParam: {},

            stop: false,

            searchWord: {},  //下拉筛选的时候，后面搜索比前面搜索先返回，导致前面搜索的结果被后面搜索的结果覆盖，所以加一个当前搜索的标识
            //药物相互作用字段
            drInteractValue: '',
            drInteractCol: {
                list: [],
                loading: false,
            },

            isSearchLeft: false,  //是否展示左侧筛选框
            isListOutQueryView: true,  //是否展示列表外查询条件
        }
    },
    created() {
        if (!this.stop) {
            this.initFirst();
        }
    },
    watch: {
        '$route': function () {
            if (!this.stop) {
                this.initFirst();
            }
        }
    },
    methods: {
        //左侧筛选
        searchShowLeft() {
            this.isSearchLeft = true
        },
        //关闭左侧筛选框
        searchClose() {
            this.isSearchLeft = false
        },

        changeName(str) {
            let arr = [...str];
            arr.forEach((item, index) => {
                if (item === "_") {
                    arr.splice(index, 1);
                    arr[index] = arr[index].toUpperCase();
                }
            });
            return arr.join('')
        },
        initFirst() {
            this.loaded = false

            this.list = [];
            this.pageParam = {
                page: 1,
                pageSize: this.defalutSize || variable.basePageSize,
                total: 0,
                error: '',
            };
            this.sortParam = {};

            if (this.mainKey) {
                let mainKey = this.mainKey
                this.cols = []
                // 当存在_patch-new-1的时候，特殊处理
                const patchNew1Index = mainKey.indexOf('_patch-new-1')
                if (patchNew1Index > -1) {
                    mainKey = mainKey.substring(0, patchNew1Index)
                    // this.specialParam = {
                    //     taskType: '%一致性评价%'
                    // }
                    this.config.key = mainKey;
                    this.currentParam = this.refer[this.mainKey]
                } else {
                    this.config.key = mainKey;
                    this.currentParam = this.refer[this.config.key]
                }
            }
            if (this.config.key) {
                this.cols = this.$help.store.get(this.config.key + 'Table') || []
                // this.$store.commit('loading');
                api.get('setting/sysRoleField/querySysRoleFieldList', { tableName: this.config.key })
                    .then(res => {
                        this.noAuthField = res.data
                    })
                    .finally(() => {
                        api.get('setting/sysTableField/querySysTableFieldList', { tableName: this.config.key }).then(res => {
                            this.cols = res.data.map(item => {
                                item.finalShowTxt = (item.viewName || item.name).replace(/\\n/g, '')
                                item.fieldName = this.$help.formatToHump(item.fieldName)
                                if (item.listStipulate == 1) {
                                    item.fixed = 'left'
                                } else if (item.listStipulate == 2) {
                                    console.log(item)
                                    item.fixed = 'right'
                                }
                                return item
                            })
                            this.outSearchCols = this.cols.filter(item => {
                                return item.listQueryView === '是' && item.listOutQueryView === '是'
                            })
                            this.$help.store.set(this.config.key + 'Table', JSON.stringify(res.data))
                            this.$store.commit('loaded');
                            this.init();
                        })
                    })
            } else {
                this.init()
            }
            this.$nextTick(() => {
                this.loaded = true
            })
        },
        getParam() {
            let obj = this.$help.joinObj({
                page: {
                    pageNo: this.pageParam.page,
                    pageSize: this.pageParam.pageSize,
                },
                sort: this.sortParam,
                conditionList: this.$help.deepClone(this.outSearchReal),
            }, this.productModel ? { model: this.$help.deepClone(this.baseQuery) } : this.$help.deepClone(this.baseQuery) ? { params: this.$help.deepClone(this.baseQuery) } : {})

            if (this.defaultConditionList && this.defaultConditionList.length > 0) {
                obj.conditionList = obj.conditionList.concat(this.defaultConditionList)
            }

            if (this.unModel) {
                obj.model = this.followed
            }
            if (this.cndCluster) {
                obj.cndCluster = this.cndCluster
            }

            if (this.paramFunc) {
                obj = this.paramFunc(obj)
            }


            this.searchCols.forEach(item => {
                if (item.model) {
                    switch (item.field.listQueryModel) {
                        case variable.searchType.LIKE:
                            obj.conditionList.push({
                                name: item.field.fieldName,
                                op: 'like',
                                value: item.model,
                            })
                            break;
                        case variable.searchType.SELECT:
                            if (item.model && item.model.length > 0) {
                                obj.conditionList.push({
                                    name: item.field.fieldName,
                                    op: 'in',
                                    value: item.model,
                                })
                            }
                            break;
                        case variable.searchType.EQ:
                            if (item.model && item.model.length > 0) {
                                obj.conditionList.push({
                                    name: item.field.fieldName,
                                    op: 'eq',
                                    value: item.model,
                                })
                            }
                            break;
                        default:
                            if (item.model && item.model.length === 2 && !item.model.includes(undefined)) {
                                obj.conditionList.push({
                                    name: item.field.fieldName,
                                    op: 'ge',
                                    value: item.model[0],
                                })
                                obj.conditionList.push({
                                    name: item.field.fieldName,
                                    op: 'le',
                                    value: item.model[1]
                                })
                            }
                    }
                }
            });
            const query = this.$help.deepClone(this.$route.query)
            //药品招投标数据库限制参数
            if (this.config.key === 'mainf_drug_winning_informations' && query.isParam) {
                let param = this.$help.store.get(query.str) || []
                obj.conditionList = obj.conditionList.concat(param)
                delete query.isParam
                delete query.str
            }

            if (JSON.stringify(this.sortParam) !== '{}') {
                delete query.priceState
            }
            // 屏蔽accurate参数
            delete query.accurate
            if (JSON.stringify(query) !== '{}' && query.priceState) {
                obj.sort.priceState = query.priceState
            } else if (JSON.stringify(query) === '{}') {
                obj = help.joinObj(obj, { params: help.joinObj(obj.params, this.specialParam) })
            } else {
                obj = help.joinObj(obj, { params: help.joinObj(query, this.specialParam) })
            }

            return obj
        },
        getParam1() {
            return this.getParam()
        },

        outSearch() {
            this.outSearchReal = []
            this.outSearchCols.forEach(col => {
                const val = this.outSearchForm[col.fieldName]
                if (val && val.length > 0) {
                    if (col.listQueryModel === variable.searchType.SELECT) {
                        this.outSearchReal.push({
                            name: col.fieldName,
                            op: 'in',
                            value: val,
                        })
                    } else if (col.listQueryModel === variable.searchType.LIKE) {
                        this.outSearchReal.push({
                            name: col.fieldName,
                            op: 'like',
                            value: val,
                        })
                    }
                }
            })
            this.init()
        },
        //药物相互作用单筛选
        drInteractSearch() {
            this.cndCluster = {}
            this.drInteractCol.list.forEach(item => {
                if (item.dataCode === this.drInteractValue) {
                    this.cndCluster = {
                        op: 'or',
                        cnds: [
                            {
                                name: 'firstDataName',
                                op: 'like',
                                value: item.dataName
                            },
                            {
                                name: 'secondDataName',
                                op: 'like',
                                value: item.dataName
                            }
                        ]
                    }
                }
            })
            this.init()
        },

        viewChange() {
            this.$refs.table.forceComputed = Math.random()
        },
        closeTag(key) {
            const query = help.deepClone(this.$route.query);
            delete query[key];
            const route = this.$router.resolve({
                path: this.$route.path,
                query
            })
            window.location.replace(route.href);
        },

        leadingOut() {
            this.$store.commit('confirm', {
                content: '确定导出吗',
                callback: (next, back) => {
                    api.post(this.currentParam.export, this.getParam())
                        .then(res => {
                            if (res.code === this.$variable.SUCCESS_CODE) {
                                // done()
                                // this.$store.commit('success', '导出成功，请在会员信息里查看导出记录')
                                this.$help.getExportStatus(res.data.id, data => {
                                    if (data === true) {
                                        next()
                                        this.$store.commit('success', '导出成功')
                                    } else {
                                        back()
                                        this.$store.commit('error', data)
                                    }
                                })

                            } else if (res.code === 'N0001') {
                                next()
                                this.leadingOutRemainder(res.message)
                            } else {
                                back()
                                this.$store.commit('error', res.message)
                            }
                        })
                }
            })
        },

        summaryFun(param) {
            const { columns } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '总计'
                } else {
                    sums[index] = this.sumData[column.property]
                }
            })
            return sums
        },

        // 是否导出剩余的
        leadingOutRemainder(txt) {
            this.$store.commit('confirm', {
                content: txt,
                callback: (next, back) => {
                    api.post(this.currentParam.export, this.$help.joinObj(this.getParam(), { model: true }))
                        .then(res => {
                            if (res.code === this.$variable.SUCCESS_CODE) {
                                // done()
                                // this.$store.commit('success', '导出成功，请在会员信息里查看导出记录')
                                this.$help.getExportStatus(res.data.id, data => {
                                    if (data === true) {
                                        next()
                                        this.$store.commit('success', '导出成功')
                                    } else {
                                        back()
                                        this.$store.commit('error', data)
                                    }
                                })

                            } else {
                                back()
                                this.$store.commit('error', res.message)
                            }
                        })
                }
            })
        },

        selectRemote(item, args, type) {
            let newObj = null
            if(this.newSelectRemote){
                newObj =  this.newSelectRemote(item)
            }
            if (type === 'focus' && item.list && item.list.length > 0) {
                return
            }
            const str = args ? encodeURIComponent(args) : ''
            this.$set(item, 'loading', true)
            const param = this.getParam1()
            delete param.conditionList
            if(newObj){    //下拉框需要带入筛选条件
                param.conditionList = [newObj]
            }
            this.searchWord[item.fieldName] = str
            this.$api.post(`setting/sysTableField/distinctFieldValue?tableName=${this.config.key || this.key}&fieldName=${item.fieldName}&keyword=${str}`, param)
                .then(res => {
                    if (this.searchWord[item.fieldName] === str) {
                        this.$set(item, 'list', res.data)
                    }
                })
                .finally(() => {
                    item.loading = false
                })

        },
        //药物相互作用下拉框
        drInteractRemote(item, args, type) {
            if (type === 'focus' && item.list && item.list.length > 0) {
                return
            }
            const str = args ? encodeURIComponent(args) : ''
            this.$set(item, 'loading', true)
            // const param = this.getParam1()
            // delete param.conditionList
            // this.searchWord[item.fieldName] = str
            this.$api.get(`dr/drInteract/queryAimBaseDrugsListByKeyword?keyword=${str}`)
                .then(res => {
                    console.log('搜索结果', res);
                    // if (this.searchWord[item.fieldName] === str) {
                    this.$set(item, 'list', res.data)
                    // }
                })
                .finally(() => {
                    item.loading = false
                })

        },

        // region 列表相关方法
        getList(hideLoading = false) {
            this.list = []
            this.error = ''
            !hideLoading && (this.loading = true)
            api.post(this.pageApi ? this.pageApi : this.currentParam.page, this.getParam(), { 'Visit-Id': this.$help.getVisitId(this.visitId) })
                .then(res => {
                    if (res.code !== '00000') {
                        this.pageParam.error = res.message
                    }
                    if (res.code === 'A0004') {
                        this.error = res.code
                    } else {
                        if (res.data.totalCount === 0) {
                            this.$emit('empty')
                        }
                        res.data.records = res.data.records.map(item => {
                            this.noAuthField.forEach(field => {
                                item[field.fieldName] = '无权限'
                            })

                            return item
                        })
                        this.list = this.listComputed ? this.listComputed(res.data.records, res.data) : res.data.records
                        this.pageParam.total = res.data.totalCount
                        if (res.data && res.data.sumData) {
                            this.sumData = this.sumDataComputed ? this.sumDataComputed(res.data.sumData) : res.data.sumData
                        }
                    }
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        init() {
            this.pageParam.page = 1;
            this.getList();
        },
        handleFilter(cols) {
            this.searchCols = cols
            this.init()
        },
        sort(param) {
            this.sortParam = {}
            if (param) {
                this.sortParam = param
            }
            this.init();
        },
        handleCurrentChange(val) {
            this.pageParam.page = val
            this.getList()
        },
        handleSizeChange(val) {
            this.pageParam.pageSize = val
            this.handleCurrentChange(1)
        },

        handleRowDbClickChange(row) {
            this.drawer.id = row.id;
            this.drawer.show = true;
        },
        // endregion
    },
}